app-user-menu-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;


  #user-menu-button {
    color: white;

    .mdc-button__label {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .icon-only {
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    color:white;
  }
}
