import {
  inject, Injectable, Injector 
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { castArray } from 'lodash';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import {
  Observable, of, Subscription, timer 
} from 'rxjs';
import {
  catchError, switchMap, take, takeWhile 
} from 'rxjs/operators';
import { PasswordResetDialogComponent } from 'src/app/password-reset-dialog/password-reset-dialog.component';
import { LocalStorage } from '~core/services/local-storage';
import { WINDOW } from '~core/services/window/window.service';
import { AppState } from '~core/states/app/app.state';
import { SafeTKDB } from '~indexedDB';
import { NetworkState } from '~offline/states/network-state/network.state';
import { PermissionsState } from '~permissions/state/permissions.state';
import { LoginControllerService } from '~shared/services/app-services/apiLoginAppController';

import { AuthState } from '../states/auth.state';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private appState = inject(AppState);
  private indexedDB = inject(SafeTKDB);
  private localStorage = inject(LocalStorage);
  private loginSvc = inject(LoginControllerService);
  private networkState = inject(NetworkState);
  private router = inject(Router);
  private state = inject(AuthState);
  private dialog = inject(MatDialog);
  private toastr = inject(ToastrService);
  private permissionsState = inject(PermissionsState);
  private window = inject(WINDOW);

  refreshTimerSub: Subscription;

  getUserRoles() {
    return [ 'admin', 'user' ];
  }

  isAuthenticated(): boolean {
    return this.state.get('isLoggedIn');
  }

  async init() {
    if (!navigator.cookieEnabled) {
      this.toastr.warning('Access Denied to sessionStorage, try enabling Cookies');
    }

    const navigationType = (this.window.performance.getEntriesByType("navigation")?.[0] as any)?.type;

    if (navigationType == 'back_forward') {
      this.removeTokens();
    }

    let exp = +sessionStorage.getItem('exp');
    let token = sessionStorage.getItem('token');
    let refreshToken = sessionStorage.getItem('refreshToken');
    let user = sessionStorage.getItem('user');


    let offlineToken: any;

    if (this.indexedDB?.db?.auth) {
      offlineToken = await this.indexedDB.db.auth.get(1);
    }

    if (offlineToken && (!user || !token)) {
      exp = offlineToken?.exp;
      token = offlineToken?.token;
      refreshToken = offlineToken?.refreshToken;
      user = offlineToken?.user;
    }

    if (user && token) {
      this.state.set('exp', +exp);
      this.state.set('token', token);
      this.state.set('refreshToken', refreshToken);
      await this.refreshToken(refreshToken);

      if (!this.appState.get('connectionStringName')) {
        this.appState.set('connectionStringName', localStorage.getItem('lastConnectionStringName'));
      }
    } else {
      this.removeTokens();
    }
  }

  isAuthorized(accessRoles: string | string[] = []) {
    accessRoles = castArray(accessRoles);

    return true;
  }

  login(connectionStringName: string, facilityId: number, username: string, password: string): Observable<any> {
    console.debug('Login call started...');
    return this.loginSvc.login(connectionStringName, facilityId, username, password)
      .pipe(
        catchError(error => {
          console.error(`Login Error: ${error?.message}`);
          this.toastr.error('Authorization Failed');
          return of(false);
        })
      );
  }

  logout(): Observable<boolean> {


    this.dialog.closeAll();

    return this.loginSvc.logout()
      .pipe(
        switchMap(() => {
          this.removeTokens();
          this.state.set('loggedOut',true);
          return this.router.navigateByUrl('/login');
        }),
        catchError(err => {
          this.removeTokens();
          console.warn(`logout call FAILED at ${DateTime.local().toJSDate()}.\n Redirecting to login...`);
          this.state.set('loggedOut',true);
          return this.router.navigateByUrl('/login');
        })
      );
  }

  openResetPasswordDialog(): Observable<boolean> {
    const dialogOptions = {
      width: '640px',
      hasBackdrop: true
    };

    return this.dialog.open(PasswordResetDialogComponent, dialogOptions)
      .afterClosed();
  }

  async redirectUser(): Promise<any> {
    const redirect = this.localStorage.getItem('redirect');

    if (redirect) {
      return this.router.navigateByUrl(redirect);
    }

    return this.router.navigateByUrl('/');
  }

  async refreshToken(refreshToken = this.state.get('refreshToken')): Promise<any> {
    this.state.set('tokenRefreshing', true);
    let authToken: any;

    if (!this.networkState.get('isOnline')) {
      if (this.indexedDB?.db?.auth) {
        authToken = await this.indexedDB.db.auth.get(1);
      }
    } else {
      authToken = await this.loginSvc.refreshToken(refreshToken).toPromise();
    }

    return this.setSession(authToken);
  }

  async removeTokens(): Promise<void> {
    /* TODO: replace sessionStorage with InjectionToken */
    sessionStorage.clear();
    this.permissionsState.set('allPermissions', null);
    this.state.set('exp', null);
    this.state.set('token', null);
    this.state.set('refreshToken', null);
    this.state.set('loggedOut',false);
    this.appState.set('user', null);
    this.state.set('isLoggedIn', false);

    if (this.indexedDB?.db?.auth) {
      await this.indexedDB.db.auth.clear();
    }

    return;
  }

  private setRefreshTimer(): void {
    let tokenExpirationDateTime = DateTime.fromSeconds(this.state.get('exp')).toJSDate();

    if (this.refreshTimerSub) {
      console.debug('refresh timer existed, unsubscribing');
      this.refreshTimerSub.unsubscribe();
    }

    console.debug(`User Token refreshed at: ${DateTime.local().toFormat('hh:mm:ss a ZZZZ') }`);
    console.debug(`User Token will expire at: ${DateTime.fromJSDate(tokenExpirationDateTime).toFormat('hh:mm:ss a ZZZZ')}`);
    console.debug(`User OFFLINE Token will expire at: ${DateTime.fromJSDate(tokenExpirationDateTime).plus({ days: 1 })
      .toFormat('mm/dd/yyyy hh:mm:ss a ZZZZ')}`);

    if (!this.networkState.get('isOnline')) {
      console.debug('User is OFFLINE');
      tokenExpirationDateTime = DateTime.fromJSDate(tokenExpirationDateTime)
        .plus({ days: 1 })
        .toJSDate();
    }

    this.refreshTimerSub = timer(tokenExpirationDateTime)
      .pipe(
        takeWhile(() => this.state.get('isLoggedIn') === true),
        switchMap(() => this.refreshToken()),
        take(1)
      )
      .subscribe(() => console.debug('Token Refreshed'));
  }

  async setSession(authResponse: any): Promise<void> {
    if (authResponse) {
      const {
        exp, expRefresh, token, refreshToken, user
      } = authResponse;
      if (exp && expRefresh && token && refreshToken) {
        this.state.set('exp', +exp);
        this.state.set('expRefresh', +expRefresh);
        this.state.set('token', token);
        this.state.set('refreshToken', refreshToken);
        this.state.set('isLoggedIn', true);
        this.state.set('loggedOut', false);

        const user = authResponse?.user ?? this.appState.get('user');

        const authData = {
          id: 1,
          exp,
          expRefresh,
          token,
          refreshToken,
          user
        };

        if (this.indexedDB?.db?.auth) {
          await this.indexedDB.db.auth.put(authData);
        } else {
          const queue = this.appState.get('indexedDBQueue') ?? [];

          queue.push({
            table: 'auth',
            row: authData
          });

          this.appState.set('indexedDBQueue', queue);
        }

        this.setRefreshTimer();
        this.state.set('tokenRefreshing', false);
        return;
      }
    } else {
      await this.logout().toPromise();
    }
  }
}

export function InitializeAuth(injector: Injector): () => void {
  return async () => {
    const authSvc = injector.get(AuthService);
    await authSvc.init();
  };
}
